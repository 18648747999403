@import 'style/colors';
@import 'style/fonts';

.editorWrapper {
  background-color: $white;
  border: 1px solid $secondary-100;
  border-radius: 3px;
}

/* Basic editor styles */
.editor {
  max-height: 100%;
  padding: 12px;
  overflow: auto;

  @include editor-typography;

  &.dark {
    color: $white;
    background-color: $dark-blue;
  }
}

.proseMirror {
  height: 250px;

  &:focus {
    outline: none;
  }
}

.mention {
  display: inline-block;
  padding: 3px 8px;

  // Container's font-size - 2px
  // to properly display options inside h1-h6 etc
  font-size: calc(1em - 2px);
  font-weight: $semi-bold;
  color: inherit;
  text-decoration: inherit;
  background-color: inherit;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}

.message {
  font-size: 11px;
}

.tip {
  font-size: 11px;
  font-weight: $semi-bold;
}

.tipHighlighted {
  color: $cyan-400;
}

.errorMessage {
  margin-top: 4px;
  font-size: $font-size-sm;
  color: $danger;
}
